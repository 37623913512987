import {
  Button,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  NativeSelect,
  NumberInput,
  Paper,
  rem,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { FileWithPath } from '@mantine/dropzone';
import { isInRange, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCalendarTime,
  IconCheck,
  IconCirclePlus,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import CustomDropzone from '../../../components/CustomDropzone/CustomDropzone';
import FileViewer from '../../../components/FileViewer/FileViewer';
import ActionDeleteIcon from '../../../components/icons/ActionDeleteIcon';
import LabelText from '../../../components/LabelText/LabelText';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCurrency from '../../../hooks/useCurrency';
import ExpenseReportService, {
  ExpenseReportDataPayloadData,
} from '../../../services/ExpenseReportService';
import type {
  ExpenseReportLabel,
  ExpenseReportObject,
  ExpenseReportType,
  User,
} from '../../../types/types';
import { loadExpenseReportAttachment } from '../../../utils/attachmentFile';
import { africaCurrencies, defaultCurrencies } from '../../../utils/currencies';
import { MS_WHITE_LABEL_ID } from '../../../variables/GlobalVariables';
import s from './ExpenseReportsUser.module.sass';

type FormValues = {
  type: ExpenseReportType;
  label: ExpenseReportLabel;
  dateOfExpense: Date;
  comment: string;
  internalCustomers: { value: string }[] | undefined;
  externalCustomers: { value: string }[] | undefined;
  currency: string;
  amount: number;
  vats: { vat: number; vatAmount: number }[];
  distance: number | undefined;
  placeOfDeparture: string | undefined;
  placeOfArrival: string | undefined;
  files: FileWithPath[];
};

type Props = {
  closeModal: () => void;
  expenseReport?: ExpenseReportObject;
  edit?: boolean;
  refetch?: () => void;
  userProfile: User;
};

function Customers(props: {
  text: any;
  item: { value: string };
  label: any;
  placeholder: string;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) {
  return (
    <Group justify={'center'} align={'flex-end'}>
      <TextInput
        required
        label={<LabelText text={props.text} />}
        placeholder={props.placeholder}
        value={props.item.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <ActionDeleteIcon label={props.label} onClick={props.onClick} />
    </Group>
  );
}

export default function ExpenseReportRequestForm({
  closeModal,
  expenseReport,
  edit = true,
  refetch,
  userProfile,
}: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();
  const [isNewForm, setIsNewForm] = useState<boolean>(false);
  const { company } = userProfile;
  const queryClient = useQueryClient();
  const [expenseReportAttachmentFilePath, setExpenseReportAttachmentFilePath] =
    useState<string | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      type: 'DISPLACEMENT',
      label: 'FUEL',
      dateOfExpense: new Date(),
      comment: '',
      internalCustomers: [],
      externalCustomers: [],
      currency: useCurrency({ companyCountryCode: company.countryCode }),
      amount: 0,
      vats: [{ vat: 0, vatAmount: 0 }],
      distance: 0,
      placeOfDeparture: '',
      placeOfArrival: '',
      files: [],
    },
    validate: {
      amount: isInRange({ min: 0.01 }, 'Saisir un montant TTC'),
      vats: {
        vat: (value, values) =>
          values.label !== 'KILOMETRICALLOWANCE' && value < 0.01
            ? 'Saisir une TVA'
            : null,
        vatAmount: (value, values) =>
          values.label !== 'KILOMETRICALLOWANCE' && value < 0.01
            ? 'Saisir un montant de TVA'
            : null,
      },
      distance: (value, values) =>
        value && values.label === 'KILOMETRICALLOWANCE' && value < 0.01
          ? 'Saisir une distance en km'
          : null,
    },
  });

  function combineArrays(array1: number[] | null, array2: number[] | null) {
    const result = [];

    if (array1 && array2) {
      for (let i = 0; i < array1.length; i++) {
        result.push({ vat: array1[i], vatAmount: array2[i] });
      }
    }

    return result;
  }

  useEffect(() => {
    if (expenseReport) {
      const data: FormValues = {
        type: expenseReport.type,
        label: expenseReport.label,
        dateOfExpense: new Date(expenseReport.dateOfExpense),
        comment: expenseReport.comment,
        internalCustomers: expenseReport.internal
          ?.split(',')
          .map((item) => ({ value: item })),
        externalCustomers: expenseReport.external
          ?.split(',')
          .map((item) => ({ value: item })),
        currency: expenseReport.currency,
        amount: expenseReport.amount,
        vats: combineArrays(
          expenseReport.additionVATList,
          expenseReport.additionValueVATList
        ),
        distance: expenseReport?.distance,
        placeOfDeparture: expenseReport?.placeOfDeparture,
        placeOfArrival: expenseReport?.placeOfArrival,
        files: [],
      };
      form.setValues(data);
      if (expenseReport.id && expenseReport.attachment) {
        loadExpenseReportAttachment(
          expenseReport.id,
          expenseReport.attachment,
          company.id,
          access_token,
          setExpenseReportAttachmentFilePath
        );
      }
    }
  }, [expenseReport]);
  useEffect(() => {
    if (form.values.files.length > 0) {
      setExpenseReportAttachmentFilePath(
        URL.createObjectURL(form.values.files[0])
      );
    }
  }, [form.values.files]);

  const select = (
    <NativeSelect
      id={'expenseReport-currency'}
      data={
        MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
          ? africaCurrencies().map((currency) => {
              return { value: currency, label: currency };
            })
          : defaultCurrencies().map((currency) => {
              return { value: currency, label: currency };
            })
      }
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: rem(102),
        },
      }}
      {...form.getInputProps('currency')}
    />
  );

  function onCbSuccess(notifId: string) {
    showNotification({
      id: notifId,
      title: t('w.success'),
      message: t('expenseReport.added'),
      color: 'green',
      icon: <IconCheck />,
    });
    if (isNewForm) {
      form.reset();
    } else {
      closeModal();
    }
    queryClient.invalidateQueries({
      queryKey: [
        'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
        company.id,
      ],
    });
    refetch && refetch();
  }

  function onCbError(notifId: string) {
    showNotification({
      id: notifId,
      title: t('w.error'),
      message: t('expenseReport.message.error.create'),
      color: 'red',
      icon: <IconX />,
    });
  }

  const { mutate: mutateCreate, isLoading: isCreateLoading } = useMutation({
    mutationFn: (variables: {
      data: ExpenseReportDataPayloadData;
      file: FileWithPath | null;
    }) =>
      ExpenseReportService.create(
        userProfile.id,
        variables.data,
        variables.file
      ),
    onSuccess: () => onCbSuccess('create'),
    onError: () => onCbError('error-create'),
  });

  const {
    mutate: updateExpenseReport,
    isLoading: isUpdateExpenseReportLoading,
  } = useMutation({
    mutationFn: (variables: {
      data: ExpenseReportDataPayloadData;
      file: FileWithPath | null;
    }) =>
      ExpenseReportService.update(
        company.id,
        variables.data,
        expenseReport?.id,
        variables.file
      ),
    onSuccess: () => onCbSuccess('update-expense-report'),
    onError: () => onCbError('error-update-expense-report'),
  });

  const loading: boolean = isCreateLoading || isUpdateExpenseReportLoading;

  const types = useMemo(
    () => [
      { value: 'DISPLACEMENT', label: t('expenseReport.type.DISPLACEMENT') },
      { value: 'MISSION', label: t('expenseReport.type.MISSION') },
      { value: 'RECEPTION', label: t('expenseReport.type.RECEPTION') },
      { value: 'OTHER', label: t('expenseReport.type.OTHER') },
    ],
    []
  );

  const wordings = useMemo(() => {
    switch (form.values.type) {
      case 'MISSION':
        return [
          { value: 'MEAL', label: t('expenseReport.label.MEAL') },
          { value: 'LODGING', label: t('expenseReport.label.LODGING') },
        ];
      case 'RECEPTION':
        return [{ value: 'MEAL', label: t('expenseReport.label.MEAL') }];
      case 'OTHER':
        return [{ value: 'OTHER', label: t('expenseReport.type.other') }];
      default:
        return [
          { value: 'FUEL', label: t('expenseReport.label.FUEL') },
          { value: 'PLANE', label: t('expenseReport.label.PLANE') },
          { value: 'TRAIN', label: t('expenseReport.label.TRAIN') },
          { value: 'TAXI', label: t('expenseReport.label.TAXI') },
          { value: 'TOLL', label: t('expenseReport.label.TOLL') },
          { value: 'PARKING', label: t('expenseReport.label.PARKING') },
          { value: 'CARRENTAL', label: t('expenseReport.label.CARRENTAL') },
          { value: 'BIKERENTAL', label: t('expenseReport.label.BIKERENTAL') },
          { value: 'TRANSITPASS', label: t('expenseReport.label.TRANSITPASS') },
          {
            value: 'KILOMETRICALLOWANCE',
            label: t('expenseReport.label.KILOMETRICALLOWANCE'),
          },
        ];
    }
  }, [form.values.type]);

  useEffect(() => {
    switch (form.values.type) {
      case 'MISSION':
        form.setFieldValue('label', 'MEAL');
        break;
      case 'RECEPTION':
        form.setFieldValue('label', 'MEAL');
        break;
      case 'OTHER':
        form.setFieldValue('label', 'OTHER');
        break;
      default:
        form.setFieldValue('label', 'FUEL');
        break;
    }
  }, [form.values.type]);

  function handleAddCustomerClick(
    customers: { value: string }[] | undefined,
    isInternalCustomers: boolean
  ): void {
    if (isInternalCustomers) {
      form.insertListItem('internalCustomers', { value: '' });
    } else {
      form.insertListItem('externalCustomers', { value: '' });
    }
  }

  function handleDeleteCustomerClick(
    index: number,
    isInternalCustomers: boolean
  ) {
    if (isInternalCustomers) {
      form.removeListItem('internalCustomers', index);
    } else {
      form.removeListItem('externalCustomers', index);
    }
  }

  function handleCustomerFullnameChange(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    isInternalCustomer: boolean
  ) {
    const value = event.currentTarget.value;
    if (isInternalCustomer) {
      form.setFieldValue(`internalCustomers.${index}.value`, value);
    } else {
      form.setFieldValue(`externalCustomers.${index}.value`, value);
    }
  }

  function onExpenseReportFormSubmit(values: FormValues) {
    const dateOfExpenseFormatted = dayjs(values.dateOfExpense).format(
      'DD/MM/YYYY'
    );

    function getInternal() {
      return values?.internalCustomers?.map((item) => item.value).toString();
    }

    function getExternal() {
      return values?.externalCustomers?.map((item) => item.value).toString();
    }

    function getAdditionVATList() {
      return values.vats.map((item) => item.vat);
    }

    function getAdditionValueVATList() {
      return values.vats.map((item) => item.vatAmount);
    }

    const mealData: ExpenseReportDataPayloadData = {
      type: values.type,
      label: values.label,
      dateOfExpense: dateOfExpenseFormatted,
      amount: values.amount,
      currency: values.currency,
      comment: values.comment,
      internal: getInternal(),
      external: getExternal(),
      additionVATList: getAdditionVATList(),
      additionValueVATList: getAdditionValueVATList(),
    };

    let kmAllowanceData: ExpenseReportDataPayloadData = {
      type: values.type,
      label: values.label,
      dateOfExpense: dateOfExpenseFormatted,
      amount: values.amount,
      currency: values.currency,
      comment: values.comment,
      placeOfDeparture: values.placeOfDeparture,
      placeOfArrival: values.placeOfArrival,
      distance: values.distance,
    };

    let otherData: ExpenseReportDataPayloadData = {
      type: values.type,
      label: values.label,
      dateOfExpense: dateOfExpenseFormatted,
      amount: values.amount,
      currency: values.currency,
      comment: values.comment,
      additionVATList: getAdditionVATList(),
      additionValueVATList: getAdditionValueVATList(),
    };

    const totalAdditionalValueVAT = values.vats.reduce(
      (sum, { vatAmount }) => sum + vatAmount,
      0
    );

    if (expenseReport) {
      let expenseReportData: ExpenseReportDataPayloadData = {
        type: values.type,
        label: values.label,
        dateOfExpense: dateOfExpenseFormatted,
        amount: values.amount,
        currency: values.currency,
        comment: values.comment,
        additionVATList: getAdditionVATList(),
        additionValueVATList: getAdditionValueVATList(),
        placeOfDeparture: values.placeOfDeparture,
        placeOfArrival: values.placeOfArrival,
        distance: values.distance,
        state: expenseReport.status.state,
        internal: getInternal(),
        external: getExternal(),
      };
      updateExpenseReport({
        data: expenseReportData,
        file: values.files[0] ? values.files[0] : null,
      });
    } else {
      if (values.label !== 'KILOMETRICALLOWANCE') {
        if (totalAdditionalValueVAT < values.amount) {
          form.clearFieldError('amount');
          switch (values.label) {
            case 'MEAL': {
              mutateCreate({
                data: mealData,
                file: values.files ? values.files[0] : null,
              });
              break;
            }
            default:
              mutateCreate({
                data: otherData,
                file: values.files ? values.files[0] : null,
              });
          }
        } else {
          form.setFieldError(
            'amount',
            'Inférieur à la somme des montants de TVA'
          );
          showNotification({
            id: 'amount-alert',
            title: t('w.warning'),
            message:
              'La somme des montants de TVA est supérieure au montant TTC',
            color: 'orange',
            icon: <IconAlertTriangle />,
          });
        }
      } else {
        mutateCreate({
          data: kmAllowanceData,
          file: values.files ? values.files[0] : null,
        });
      }
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => onExpenseReportFormSubmit(values))}
    >
      <LoadingOverlay visible={loading} />

      <SimpleGrid cols={2} spacing={'md'}>
        <Stack>
          <SimpleGrid cols={2} spacing={'md'}>
            <Select
              id={'expenseReport-type'}
              label={<LabelText text={'Type'} />}
              data={types}
              {...form.getInputProps('type')}
              disabled={!edit}
            />
            <Select
              id={'expenseReport-label'}
              label={<LabelText text={t('w.wording')} />}
              data={wordings}
              {...form.getInputProps('label')}
              disabled={!edit}
            />
            <DateInput
              id={'expenseReport-date'}
              leftSection={<IconCalendarTime className={s.icon} />}
              label={<LabelText text={t('expenseReport.dateOfExpense')} />}
              valueFormat={'DD/MM/YYYY'}
              {...form.getInputProps('dateOfExpense')}
              disabled={!edit}
              leftSectionPointerEvents="none"
            />
            <Textarea
              id={'expenseReport-comment'}
              label={<LabelText text={t('w.comment')} />}
              {...form.getInputProps('comment')}
              disabled={!edit}
              resize="vertical"
            />
            <NumberInput
              required
              label={<LabelText text={t('w.totalInclVAT')} />}
              {...form.getInputProps('amount')}
              rightSection={select}
              rightSectionWidth={92}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              fixedDecimalScale
              hideControls
              min={0}
              disabled={!edit}
            />
            {form.values.label !== 'KILOMETRICALLOWANCE' && (
              <Flex direction={'column'} gap={'xl'}>
                {form.values.vats.map((item, index) => (
                  <Flex key={index} direction={'row'} gap="sm" align="center">
                    <NumberInput
                      label={<LabelText text={t('w.vat')} />}
                      {...form.getInputProps(`vats.${index}.vat`)}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      hideControls
                      min={0}
                      disabled={!edit}
                    />
                    <NumberInput
                      label={<LabelText text={t('w.amount')} />}
                      {...form.getInputProps(`vats.${index}.vatAmount`)}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                      hideControls
                      min={0}
                      disabled={!edit}
                    />
                    {edit && (
                      <ActionDeleteIcon
                        label={t('w.deleteVAT')}
                        onClick={() => form.removeListItem('vats', index)}
                      />
                    )}
                  </Flex>
                ))}
                <Flex justify={'flex-end'}>
                  <Button
                    leftSection={<IconCirclePlus />}
                    onClick={() =>
                      form.insertListItem('vats', { vat: 0.0, vatAmount: 0.0 })
                    }
                    variant="outline"
                    disabled={!edit}
                  >
                    {t('w.addVAT')}
                  </Button>
                </Flex>
              </Flex>
            )}
            {form.values.type === 'DISPLACEMENT' &&
              form.values.label === 'KILOMETRICALLOWANCE' && (
                <TextInput
                  id={'expenseReport-place-of-departure'}
                  required
                  label={<LabelText text={t('w.placeOfDeparture')} />}
                  {...form.getInputProps('placeOfDeparture')}
                  disabled={!edit}
                />
              )}
            {form.values.type === 'DISPLACEMENT' &&
              form.values.label === 'KILOMETRICALLOWANCE' && (
                <TextInput
                  id={'expenseReport-place-of-arrival'}
                  required
                  label={<LabelText text={t('w.placeOfArrival')} />}
                  {...form.getInputProps('placeOfArrival')}
                  disabled={!edit}
                />
              )}
          </SimpleGrid>
          <Space h={'md'} />
          {form.values.type === 'DISPLACEMENT' &&
            form.values.label === 'KILOMETRICALLOWANCE' && (
              <SimpleGrid cols={1} spacing={'md'}>
                <NumberInput
                  id={'expenseReport-distance'}
                  required
                  label={<LabelText text={'Distance'} />}
                  {...form.getInputProps('distance')}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  hideControls
                  min={0}
                  disabled={!edit}
                />
              </SimpleGrid>
            )}
          <Space h={'md'} />
          <Divider size={'xs'} />
          <Space h={'md'} />
          {form.values.label === 'MEAL' && (
            <SimpleGrid
              cols={2}
              bg={'#f8f9fa'}
              style={{ borderRadius: 10 }}
              py={'sm'}
            >
              <Flex direction={'column'} gap={'xl'}>
                {form.values.internalCustomers &&
                  form.values.internalCustomers.length > 0 &&
                  form.values.internalCustomers.map((item, index) => (
                    <Customers
                      key={index}
                      text={`Invité ${index + 1}`}
                      item={item}
                      label={t('w.deleteCustomer')}
                      placeholder={t('w.fullname')}
                      onClick={() => handleDeleteCustomerClick(index, true)}
                      onChange={(event) =>
                        handleCustomerFullnameChange(event, index, true)
                      }
                      disabled={!edit}
                    />
                  ))}
                <Button
                  disabled={!edit}
                  onClick={() =>
                    handleAddCustomerClick(form.values.internalCustomers, true)
                  }
                  leftSection={<IconCirclePlus />}
                  variant={'outline'}
                >
                  {t('w.internalGuest')}
                </Button>
              </Flex>
              <Flex direction={'column'} gap={'xl'}>
                {form.values.externalCustomers &&
                  form.values.externalCustomers.length > 0 &&
                  form.values.externalCustomers.map((item, index) => (
                    <Customers
                      key={index}
                      text={`${t('w.guest')} ${index + 1}`}
                      item={item}
                      label={t('w.deleteCustomer')}
                      placeholder={t('w.fullname')}
                      onClick={() => handleDeleteCustomerClick(index, false)}
                      onChange={(event) =>
                        handleCustomerFullnameChange(event, index, false)
                      }
                      disabled={!edit}
                    />
                  ))}
                <Button
                  onClick={() =>
                    handleAddCustomerClick(form.values.externalCustomers, false)
                  }
                  leftSection={<IconCirclePlus />}
                  variant={'outline'}
                  disabled={!edit}
                >
                  {t('w.externalGuest')}
                </Button>
              </Flex>
            </SimpleGrid>
          )}
          {edit && (
            <CustomDropzone
              onDrop={(files) => form.setFieldValue('files', files)}
              files={form.values.files}
              mimeType={'expense-report'}
            />
          )}
        </Stack>
        <Paper withBorder p="md">
          {expenseReportAttachmentFilePath ? (
            <Group>
              <LabelText text={t('expenseReport.receipt')} />
              <FileViewer
                id={`expense`}
                srcUrl={expenseReportAttachmentFilePath}
                title={`expense`}
              />
            </Group>
          ) : (
            <LabelText text={t('w.noFile')} />
          )}
        </Paper>
      </SimpleGrid>

      <Space h={'md'} />
      {edit && (
        <Group justify={'flex-end'}>
          <Button variant={'subtle'} onClick={closeModal} disabled={!edit}>
            {t('w.cancel')}
          </Button>
          <Button
            leftSection={<IconCheck />}
            type={'submit'}
            onClick={() => setIsNewForm(false)}
            disabled={!edit}
          >
            {expenseReport ? t('w.modify') : t('w.validate')}
          </Button>
          {!expenseReport && (
            <Button
              leftSection={<IconCheck />}
              rightSection={<IconCirclePlus />}
              type={'submit'}
              onClick={() => setIsNewForm(true)}
              disabled={!edit}
            >
              {t('w.validateAndAddNew')}
            </Button>
          )}
        </Group>
      )}
    </form>
  );
}
