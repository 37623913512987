import { Button, Divider, Grid, Group, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCopyCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchAccountants from '../../../../hooks/useFetchAccountants';
import ProfileService from '../../../../services/ProfileService';
import type { OnBoardingType } from '../../../../types/types';

type Props = {
  onBoarding?: OnBoardingType;
  refetchOBInfos?: () => void;
};

export default function OnBoardingStatusModal({
  onBoarding,
  refetchOBInfos,
}: Props) {
  const { t } = useI18n();
  const { access_token, user } = useAuth();
  const { accountantsListData } = useFetchAccountants();

  function openConfirmOnBoarding() {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title: (
        <Title size={'h3'} component="p">
          {'Vous allez finaliser la pré-embauche de votre collaborateur'}
        </Title>
      ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () =>
        finalValidateEmployeeOnBoarding({ userId: onBoarding?.userId }),
    });
  }

  const { mutate: finalValidateEmployeeOnBoarding } = useMutation({
    mutationFn: (variables: { userId: string | undefined }) =>
      ProfileService.finalValidationOnBoarding(variables.userId, access_token),
    onSuccess: () => {
      showNotification({
        id: 'validate-onboarding-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      if (refetchOBInfos) {
        refetchOBInfos();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateFinalOnboarding'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Grid justify={'space-evenly'} grow>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Text fz={'md'} fw={'600'} c={undefined}>
          Statut collaborateur :
        </Text>
        <div>
          <Group justify={'space-between'}>
            <Text fz={'sm'} fw={'600'} c={'dimmed'}>
              {t('w.signupDate')}
            </Text>
            <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
              {onBoarding?.onBoardingEmployee?.inscriptionDate
                ? dayjs(
                    `${onBoarding?.onBoardingEmployee?.inscriptionDate}Z`
                  ).format('DD/MM/YYYY HH:mm')
                : '-'}
            </Text>
          </Group>
          {<Divider my="xs" />}
        </div>
        <div>
          <Group justify={'space-between'}>
            <Text fz={'sm'} fw={'600'} c={'dimmed'}>
              {t('w.lastUpdated')}
            </Text>
            <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
              {onBoarding?.onBoardingEmployee?.lastUpdate
                ? dayjs(
                    `${onBoarding?.onBoardingEmployee?.lastUpdate}Z`
                  ).format('DD/MM/YYYY HH:mm')
                : '-'}
            </Text>
          </Group>
          {<Divider my="xs" />}
        </div>
        <div>
          <Group justify={'space-between'}>
            <Text fz={'sm'} fw={'600'} c={'dimmed'}>
              {t('w.validationDateEmployee')}
            </Text>
            <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
              {onBoarding?.onBoardingEmployee?.validationDate
                ? dayjs(
                    `${onBoarding?.onBoardingEmployee?.validationDate}Z`
                  ).format('DD/MM/YYYY HH:mm')
                : '-'}
            </Text>
          </Group>
        </div>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Text fz={'md'} fw={'600'} c={undefined}>
          Statut entreprise :
        </Text>
        <div>
          <Text fz={'sm'} fw={'600'} c={'dimmed'}>
            {t('w.updates')}
          </Text>
          <Group justify={'space-between'}>
            {onBoarding?.onBoardingCompany?.updateDataDate?.map((item) => (
              <div style={{ marginLeft: '20px' }}>
                <Group justify={'space-between'}>
                  <Text fz={'sm'} fw={'600'} c={'darkgray'}>
                    {t('w.modification')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
                    {item.dateOfUpdate
                      ? `${dayjs(`${item.dateOfUpdate}Z`).format(
                          'DD/MM/YYYY HH:mm'
                        )} par ${item.nameOfUpdater}`
                      : '-'}
                  </Text>
                </Group>
                <Divider my="xs" />
              </div>
            ))}
          </Group>
        </div>
        <div>
          <Group justify={'space-between'}>
            <Text fz={'sm'} fw={'600'} c={'dimmed'}>
              {t('w.validationDateCompany')}
            </Text>
            <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
              {onBoarding?.onBoardingCompany?.validationDate?.dateOfUpdate
                ? `${dayjs(
                    `${onBoarding?.onBoardingCompany?.validationDate?.dateOfUpdate}Z`
                  ).format('DD/MM/YYYY HH:mm')} par ${
                    onBoarding?.onBoardingCompany.validationDate.nameOfUpdater
                  }`
                : '-'}
            </Text>
          </Group>
        </div>
      </Grid.Col>
      {accountantsListData && accountantsListData.length > 0 && (
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Text fz={'md'} fw={'600'} c={undefined}>
            Statut Gestionnaire :
          </Text>
          <div>
            <Group justify={'space-between'}>
              <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                {t('w.registerInPayroll')}
              </Text>
              <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
                {onBoarding?.onBoardingAccountant?.registerInPayroll
                  ? t('w.true')
                  : t('w.false')}
              </Text>
            </Group>
            {<Divider my="xs" />}
          </div>
          <div>
            <Group justify={'space-between'}>
              <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                {t('w.validationDateAccountant')}
              </Text>
              <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
                {onBoarding?.onBoardingAccountant?.validationDate?.dateOfUpdate
                  ? dayjs(
                      `${onBoarding?.onBoardingAccountant?.validationDate.dateOfUpdate}Z`
                    ).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </Text>
            </Group>
            {<Divider my="xs" />}
          </div>
        </Grid.Col>
      )}
      <Grid.Col span={8}>
        <Divider my="xs" />
        <div>
          <Group justify={'space-between'}>
            <Text fz={'sm'} fw={'600'} c={'dimmed'}>
              {t('w.lastValidation')}
            </Text>
            <Text fz={'sm'} fw={'600'} c={'hifivework.6'}>
              {onBoarding?.validationDate ? (
                `${dayjs(`${onBoarding?.validationDate}Z`).format(
                  'DD/MM/YYYY HH:mm'
                )} par ${onBoarding?.nameOfValidator}`
              ) : onBoarding?.userId !== user.id &&
                refetchOBInfos &&
                onBoarding?.onBoardingCompany?.validationDate?.dateOfUpdate !==
                  null &&
                ((accountantsListData &&
                  accountantsListData?.length > 0 &&
                  onBoarding?.onBoardingAccountant?.validationDate
                    ?.dateOfUpdate !== null) ||
                  !accountantsListData) ? (
                <Button
                  onClick={() => openConfirmOnBoarding()}
                  color={'#0A9182'}
                  leftSection={<IconCopyCheck />}
                >
                  {t('w.onBoardingFinalisation')}
                </Button>
              ) : (
                '-'
              )}
            </Text>
          </Group>
        </div>
      </Grid.Col>
    </Grid>
  );
}
