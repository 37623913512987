import { ACCOUNTANT } from '../types/types';

export const ConfigureWorkUnit = 'configure.workunit'; // Configurer les unités de travail
export const ConfigureEmployees = 'configure.employees'; // Configurer les employés
export const ConfigureLeaveTypes = 'configure.leavetypes'; // Configurer les types de congés
export const ConfigureBlockingPeriods = 'configure.blockingperiods'; // Configurer les périodes de blocage
export const ConfigurePublicHolidaysCompanyLeaves =
  'configure.publicholidayscompanyleaves'; // Configurer les jours fériés et congés entreprise
export const ConfigureShuttleSheetTypes = 'configure.shuttlesheettypes'; // Configurer les types de demande
export const ConfigureAccountant = 'configure.accountant'; // Configurer le gestionnaire de paie
export const ConfigurePayment = 'configure.payment'; // Configurer les informations de paiement
export const ConfigureFeatures = 'configure.features'; // Configurer les fonctionnalités
export const ConfigurePermissions = 'configure.permissions'; // Configurer les permissions
export const ConfigureCompany = 'configure.company'; // Configurer les informations de l'entreprise
export const ConfigureTools = 'configure.tools'; // Configurer les outils de l'entreprise
export const ConfigureCompanyGroup = 'configure.companygroup'; // Configurer les entreprises rattachées

export const ValidateLeavesAbsences = 'validate.leavesabsences'; // Valider et modifier les absences et présences
export const ValidateExpenseReports = 'validate.expensereports'; // Valider et modifier les notes de frais
export const ValidateEndOfMonth = 'validate.endofmonth'; // Valider et modifier les fins de mois
export const ValidatePayrollVariables = 'validate.payrollvariables'; // Valider et modifier les variables de paie
export const ValidateHolidayCounter = 'validate.holidaycounter'; // Valider et modifier les compteurs de congés
export const ValidatePayslips = 'validate.payslips'; // Valider et modifier les bulletins de paie
export const ValidateShuttleSheets = 'validate.shuttlesheets'; // Valider et modifier les demandes

export const AuthorizedReadPlanning = 'authorized.readplanning'; // Autoriser à voir le planning
export const AuthorizedReadEmployeesPayslips =
  'authorized.reademployeespayslips'; // Autoriser à voir les bulletins de paie des employés
export const AuthorizedReadEmployeesPersonalSpace =
  'authorized.reademployeespersonalspace'; // Autoriser à voir l'espace personnel des employés
export const AuthorizedEditEmployeesPersonalSpace =
  'authorized.editemployeespersonalspace'; // Autoriser à modifier l'espace personnel des employés
export const AuthorizedReadMyPersonalSpace = 'authorized.readmypersonalspace'; // Autoriser à voir mon espace personnel
export const AuthorizedEditMyPersonalSpace = 'authorized.editmypersonalspace'; // Autoriser à modifier mon espace personnel
export const AuthorizedReadCommonSpace = 'authorized.readcommonspace'; // Autoriser à voir l'espace partagé
export const AuthorizedEditCommonSpace = 'authorized.editcommonspace'; // Autoriser à modifier l'espace partagé
export const AuthorizedDeleteLeaves = 'authorized.deleteleaves'; // Autoriser à supprimer les congés
export const AuthorizedNegativeLeaveBalance = 'authorized.negativeleavebalance'; // Autoriser à poser des congés quand le solde est insuffisant
export const AuthorizedReadDailyWork = 'authorized.readdailywork'; // Autoriser à voir les présences
export const AuthorizedUpsertDailyWork = 'authorized.upsertdailywork'; // Autoriser à upsert les présences
export const AuthorizedReadAllUsersInfo = 'authorized.readallusersinfo'; // Autoriser à voir les plannings, les bulletins de paie, les espaces personnels de tous employés
export const AuthorizedStartStopTimeRecorder =
  'authorized.startstoptimerecorder'; // Autoriser à pointer ou dépointer
export const AuthorizedUpdateTimeRecorder = 'authorized.updatetimerecorder'; // Autoriser à modifier un time recorder
export const AuthorizedNotificationReceive = 'authorized.notificationreceive'; // Autoriser recevoir toute les notif de l'entreprise
export const AuthorizedEditAccountantSpace = 'authorized.editaccountantspace'; // Autoriser lire / ecrire les document de l'espace gestionnaire

export const AccountantViewClient = 'accountant.viewClient';
export const AccountantUpdateClient = 'accountant.updateClient';
export const AccountantManageTeam = 'accountant.manageTeam';
export const AccountantNotificationReceive = 'accountant.notificationreceive';

export const UserEmergencyContactEdit = 'user.emergencycontactedit';
export const UserEmergencyContactShow = 'user.emergencycontactshow';
export const UserPersonalInfoEdit = 'user.personalinfoedit';
export const UserPersonalInfoShow = 'user.personalinfoshow';
export const UserContractualInfoEdit = 'user.contractualinfoedit';
export const UserContractualInfoShow = 'user.contractualinfoshow';

export const ALL = 'ALL';
export const TEAM = 'TEAM';
export const MINE = 'MINE';

export type BuiltInPermission =
  | typeof ACCOUNTANT
  | typeof ConfigureWorkUnit
  | typeof ConfigureEmployees
  | typeof ConfigureLeaveTypes
  | typeof ConfigureBlockingPeriods
  | typeof ConfigurePublicHolidaysCompanyLeaves
  | typeof ConfigureShuttleSheetTypes
  | typeof ConfigureAccountant
  | typeof ConfigurePayment
  | typeof ConfigureFeatures
  | typeof ConfigurePermissions
  | typeof ConfigureCompany
  | typeof ConfigureTools
  | typeof ConfigureCompanyGroup
  | typeof ValidateLeavesAbsences
  | typeof ValidateExpenseReports
  | typeof ValidateEndOfMonth
  | typeof ValidatePayrollVariables
  | typeof ValidateHolidayCounter
  | typeof ValidatePayslips
  | typeof ValidateShuttleSheets
  | typeof AuthorizedReadPlanning
  | typeof AuthorizedReadEmployeesPayslips
  | typeof AuthorizedReadEmployeesPersonalSpace
  | typeof AuthorizedEditEmployeesPersonalSpace
  | typeof AuthorizedReadMyPersonalSpace
  | typeof AuthorizedEditMyPersonalSpace
  | typeof AuthorizedReadCommonSpace
  | typeof AuthorizedEditCommonSpace
  | typeof AuthorizedDeleteLeaves
  | typeof AuthorizedNegativeLeaveBalance
  | typeof AuthorizedReadDailyWork
  | typeof AuthorizedUpsertDailyWork
  | typeof AuthorizedReadAllUsersInfo
  | typeof AuthorizedStartStopTimeRecorder
  | typeof AuthorizedUpdateTimeRecorder
  | typeof AuthorizedNotificationReceive
  | typeof AuthorizedEditAccountantSpace
  | typeof AccountantViewClient
  | typeof AccountantUpdateClient
  | typeof AccountantManageTeam
  | typeof AccountantNotificationReceive
  | typeof UserEmergencyContactEdit
  | typeof UserEmergencyContactShow
  | typeof UserPersonalInfoEdit
  | typeof UserPersonalInfoShow
  | typeof UserContractualInfoEdit
  | typeof UserContractualInfoShow;
