import {
  ActionIcon,
  CopyButton,
  Flex,
  Group,
  rem,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCopy,
  IconEye,
  IconEyeSearch,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import XMLViewer from 'react-xml-viewer';

import { useI18n } from '../contexts/I18nProvider';
import ProfileService, { DpaeType } from '../services/ProfileService';
import { User } from '../types/types';
import handleErrorMessage from '../utils/handleErrorMessage';
import CustomTooltip from './CustomTooltip';

type Props = {
  dpae: DpaeType | undefined;
  employee: User | null | undefined;
  edit: boolean;
};
export default function ButtonsDpae({ dpae, employee, edit }: Props) {
  const { t } = useI18n();

  const { mutate: updateDpaeFlux } = useMutation({
    mutationFn: (variables: { userId: string }) =>
      ProfileService.updateDpaeFlux(variables.userId),
    onSuccess: (data) => {
      if (data) {
        showNotification({
          id: `update-dpae-successful`,
          title: t('w.success'),
          message: t('success.reloadDpae'),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-dpae-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: getDpaeRetour } = useMutation({
    mutationFn: (variables: { userId: string }) =>
      ProfileService.getDpaeRetour(variables.userId),
    onSuccess: (data) => {
      if (data) {
        showNotification({
          id: `update-dpae-successful`,
          title: t('w.success'),
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
        modals.open({
          modalId: `show-dpae-data`,
          title: (
            <Title size={'h3'} component="p">
              Retour Urssaf
            </Title>
          ),
          size: '80%',
          children: (
            <Flex justify={'space-between'}>
              <XMLViewer xml={data} collapsible />
              <CopyButton value={data}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? 'Copié' : 'Copier'}
                    withArrow
                    position="right"
                  >
                    <ActionIcon
                      color={copied ? 'teal' : 'gray'}
                      variant="subtle"
                      onClick={copy}
                    >
                      {copied ? (
                        <IconCheck style={{ width: rem(18) }} />
                      ) : (
                        <IconCopy style={{ width: rem(18) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Flex>
          ),
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-dpae-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: getDpaeInfo } = useMutation({
    mutationFn: (variables: { userId: string }) =>
      ProfileService.getDpae(variables.userId),
    onSuccess: (data) => {
      if (data) {
        showNotification({
          id: `update-dpae-successful`,
          title: t('w.success'),
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
        modals.open({
          modalId: `show-dpae-data`,
          title: (
            <Title size={'h3'} component="p">
              Informations envoyées à l'Urssaf
            </Title>
          ),
          size: '80%',
          children: (
            <Flex justify={'space-between'}>
              <XMLViewer xml={data.dpaeDepotFile} collapsible />
              <CopyButton value={data.dpaeDepotFile}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? 'Copié' : 'Copier'}
                    withArrow
                    position="right"
                  >
                    <ActionIcon
                      color={copied ? 'teal' : 'gray'}
                      variant="subtle"
                      onClick={copy}
                    >
                      {copied ? (
                        <IconCheck style={{ width: rem(18) }} />
                      ) : (
                        <IconCopy style={{ width: rem(18) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Flex>
          ),
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-dpae-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Group justify={edit ? 'flex-start' : 'center'}>
      <CustomTooltip label={"Consulter les informations envoyées à l'Urssaf"}>
        <ActionIcon
          variant="filled"
          size="xl"
          disabled={!Boolean(dpae)}
          onClick={() =>
            employee?.id ? getDpaeInfo({ userId: employee?.id }) : null
          }
        >
          <IconEye size={'1.2rem'} />
        </ActionIcon>
      </CustomTooltip>
      <CustomTooltip label={"Actualiser le retour de l'Urssaf"}>
        <ActionIcon
          variant="filled"
          size="xl"
          disabled={!Boolean(dpae)}
          onClick={() =>
            employee?.id ? updateDpaeFlux({ userId: employee?.id }) : null
          }
        >
          <IconRefresh size={'1.2rem'} />
        </ActionIcon>
      </CustomTooltip>
      <CustomTooltip label={"Consulter le retour de l'Urssaf"}>
        <ActionIcon
          variant="filled"
          size="xl"
          disabled={!Boolean(dpae)}
          onClick={() =>
            employee?.id ? getDpaeRetour({ userId: employee?.id }) : null
          }
        >
          <IconEyeSearch size={'1.2rem'} />
        </ActionIcon>
      </CustomTooltip>
    </Group>
  );
}
