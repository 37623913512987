import { Button, Grid } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import type { User } from '../../../types/types';
import { hasPermission, isAccountant } from '../../../utils/authorization';
import { ALL, ConfigureEmployees } from '../../../variables/BuiltInPermissions';

type Props = {
  userProfile: User;
  onClick: () => void;
};

export default function EditUserProfileButtonContainer({
  userProfile,
  onClick,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  return (
    <Grid.Col span={2}>
      {(hasPermission({ permission: ConfigureEmployees, scope: ALL }, user) ||
        isAccountant(user, userProfile.company.id)) && (
        <Button onClick={onClick} leftSection={<IconEdit />}>
          {t('w.edit')}
        </Button>
      )}
    </Grid.Col>
  );
}
