import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CompanyService, {
  CompanyGroupType,
} from '../../../services/CompanyService';
import { hasPermission } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  ALL,
  ConfigureCompanyGroup,
} from '../../../variables/BuiltInPermissions';
import CompanyCountry from './components/CompanyCountry';
import CompanyGroupForm from './components/CompanyGroupForm';
import CompanyLogoForm from './components/CompanyLogoForm';
import CompanySettingsForm from './components/CompanySettingsForm';

export default function CompanySettings() {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [activeTab, setActiveTab] = useState<string | null>('company');

  const { data: company } = useQuery({
    queryKey: ['CompanyService.getCompany', companyId],
    queryFn: () => CompanyService.getCompany(companyId),
  });

  const {
    data: companyGroupList,
    refetch: refetchCompanyGroupList,
    isLoading: isCompanyGroupLoading,
  } = useQuery({
    queryKey: ['CompanyService.listCompanyGroup', companyId],
    queryFn: () => CompanyService.listCompanyGroup(companyId),
  });

  const { mutate: deleteCompanyGroup } = useMutation({
    mutationFn: (variables: {
      companyId: string;
      companyGroupId: string | null;
    }) =>
      CompanyService.deleteCompanyGroup(
        variables.companyId,
        variables.companyGroupId
      ),
    onSuccess: () => {
      showNotification({
        id: `delete-company-group-successful`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchCompanyGroupList();
    },
    onError: (error) =>
      showNotification({
        id: `delete-company-group-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const actionButtons = useMemo(
    () => [
      <Button
        key={'new-companyGroup-button'}
        leftSection={<IconCirclePlus size={'1.2rem'} />}
        onClick={() =>
          modals.open({
            modalId: `add-new-company-group-modal`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.edit')}
              </Title>
            ),
            size: '90%',
            children: company && (
              <CompanyGroupForm
                company={company}
                refetch={refetchCompanyGroupList}
              />
            ),
          })
        }
        disabled={
          !hasPermission(
            [{ permission: ConfigureCompanyGroup, scope: ALL }],
            user
          )
        }
      >
        Ajouter une entreprise
      </Button>,
    ],
    [company, companyGroupList]
  );

  const columns: DataTableColumn<CompanyGroupType>[] = useMemo(
    () => [
      {
        accessor: 'label',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'siret',
        title: t('w.siret'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'codeApe',
        title: t('w.companyCodeApe'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'healthService',
        title: t('w.healthService'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'address',
        title: t('w.address'),
        sortable: true,
        ellipsis: true,
        render: ({ address }) => (
          <Text>
            {address.address1} {address.address2} {address.zipCode}{' '}
            {address.city}
          </Text>
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (companyGroup) =>
          companyGroup.siret !== company?.siret ? (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  size="sm"
                  color="green"
                  variant={'subtle'}
                  onClick={() =>
                    modals.open({
                      modalId: `add-new-company-group-modal`,
                      title: (
                        <Title size={'h3'} component="p">
                          {t('w.edit')}
                        </Title>
                      ),
                      size: '90%',
                      children: company && (
                        <CompanyGroupForm
                          company={company}
                          companyGroup={companyGroup}
                          refetch={refetchCompanyGroupList}
                        />
                      ),
                    })
                  }
                  disabled={
                    !hasPermission(
                      [{ permission: ConfigureCompanyGroup, scope: ALL }],
                      user
                    )
                  }
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.delete')}>
                <ActionIcon
                  size="sm"
                  color="red"
                  variant={'subtle'}
                  onClick={() =>
                    modals.openConfirmModal({
                      modalId: 'confirm-delete-company-group',
                      title: (
                        <Title size={'h3'} component="p">
                          {
                            "Êtes-vous sûr de vouloir supprimer l'entreprise rattachée ?"
                          }
                        </Title>
                      ),
                      size: 'xl',
                      labels: { cancel: t('w.cancel'), confirm: t('w.delete') },
                      onConfirm: () =>
                        companyGroup?.id
                          ? deleteCompanyGroup({
                              companyId: companyId,
                              companyGroupId: companyGroup.id,
                            })
                          : '',
                    })
                  }
                  disabled={
                    !hasPermission(
                      [{ permission: ConfigureCompanyGroup, scope: ALL }],
                      user
                    )
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'label',
      label: t('w.name'),
    },
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    setOptions(_options);
  }

  return (
    <ScreenWrapper title={t('w.settings')}>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab key={'company'} value={'company'}>
            {t('w.company')}
          </Tabs.Tab>
          <Tabs.Tab key={'companyGroup'} value={'companyGroup'}>
            {t('w.companyGroups')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="company" pt="xs">
          <Flex justify={'space-between'}>
            <CompanyLogoForm companyId={companyId} />
            {company && <CompanyCountry company={company} />}
          </Flex>
          {company && <CompanySettingsForm company={company} />}
        </Tabs.Panel>
        <Tabs.Panel value="companyGroup" pt="xs">
          <Title size={'36px'} order={1}>
            Entreprises rattachées
          </Title>
          {company && companyGroupList ? (
            <Table
              pinFirstColumn
              pinLastColumn
              rows={companyGroupList}
              options={options}
              columns={columns}
              lang={lang}
              fetching={isCompanyGroupLoading}
              withTableBorder={false}
              rightCustomActions={actionButtons}
            />
          ) : (
            <Text>Pas encore d'entreprises rattachées pour le moment</Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </ScreenWrapper>
  );
}
