import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import { Division, Role } from '../../../../types/types';
import AddEmployeeSteppers from './AddEmployeeSteppers/AddEmployeeSteppers';

type Props = {
  opened: boolean;
  onClose: () => void;
  divisions: Division[];
  roles: Role[];
  refetch: () => void;
};

export default function AddEmployeeModal({
  opened,
  onClose,
  divisions,
  roles,
  refetch,
}: Props) {
  const { t } = useI18n();

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      fullScreen
      title={t('w.addAnEmployee')}
    >
      <AddEmployeeSteppers
        divisions={divisions}
        roles={roles}
        onClose={onClose}
        refetch={refetch}
        userProfile={null}
      />
    </CustomModal>
  );
}
