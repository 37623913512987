import { FileWithPath } from '@mantine/dropzone';

import type { Address, Company } from '../types/types';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getLogoPath(companyId: string): string {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/logo/200/200`;
}

function listEmployees(companyId: string, show: string[]): Promise<any[]> {
  if (show.length > 0) {
    return request
      .get(
        `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users?${show
          .map((item) => `show=${item}`)
          .join('&')}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users`)
      .then((res) => res?.data);
  }
}

function createNewUser(
  companyId: string,
  onBoarding: boolean,
  data: any,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users?onBoarding=${onBoarding}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function getCompany(companyId: string): Promise<Company> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}`)
    .then((res) => res?.data);
}

function importEmployeesOrDivisions(
  type: string,
  file: FileWithPath,
  companyId: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/import/${type}`,
      {
        file: file,
        filename: file.name.split('.')[0],
        extension: file.name.split('.')[1],
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function exportEmployees(
  companyId: string,
  selectValid: boolean,
  selectInvalid: boolean,
  type: string,
  access_token: string,
  divisionsId: string[]
) {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users/export/${type}`,
      {
        params: { selectValid, selectInvalid, divisionsId },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function updateCompanyById(companyId: string, data: Company) {
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}`, data)
    .then((res) => res?.data);
}

function updateCompanyLogo(companyId: string, file: File, extension: string) {
  return request.patch(
    `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/updateLogo`,
    { file: file, extension: extension },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
}

function getCompanyHomeData(companyId: string) {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/home-data`)
    .then((res) => res?.data);
}

export type SubscribeCompanyViaPaymentAccount = {
  company: {
    name: string;
    mail: string;
    phone: string | undefined;
    address: {
      address1: string;
      address2: string;
      zipCode: string;
      city: string;
    };
  };
  user: {
    mail: string;
    firstname: string;
    lastname: string;
    phone: string | undefined;
  };
};

function subscribeCompanyViaPaymentAccountCompany(
  paymentAccountCompanyId: string,
  data: SubscribeCompanyViaPaymentAccount,
  linkAsAccountant: boolean
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/subscribe/${paymentAccountCompanyId}?linkAsAccountant=${linkAsAccountant}`,
      data
    )
    .then((res) => res?.data);
}

export type CompanyGroupType = {
  companyId: string;
  siret: string;
  codeApe: string;
  healthService: string;
  label: string;
  address: Address;
  id?: string | null;
};

function listCompanyGroup(companyId: string): Promise<CompanyGroupType[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}`)
    .then((res) => res?.data);
}

function createOrUpdateCompanyGroup(companyId: string, data: CompanyGroupType) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}`,
      data
    )
    .then((res) => res?.data);
}

function deleteCompanyGroup(companyId: string, companyGroupId: string | null) {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}/${companyGroupId}`
    )
    .then((res) => res?.data);
}

const CompanyService = {
  getLogoPath,
  listEmployees,
  createNewUser,
  getCompany,
  importEmployeesOrDivisions,
  exportEmployees,
  updateCompanyById,
  getCompanyHomeData,
  updateCompanyLogo,
  subscribeCompanyViaPaymentAccountCompany,
  listCompanyGroup,
  createOrUpdateCompanyGroup,
  deleteCompanyGroup,
};

export default CompanyService;
